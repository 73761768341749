import { UserSummary } from '../users/userSummary.model';

export interface UserState {
  user: UserSummary | null;
  setUser: (user: UserSummary | null) => void;
}

export const userState = (set: any): UserState => ({
  user: null,
  setUser: (user) => set(() => ({ user })),
});
