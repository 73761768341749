import { usePathname } from 'next/navigation';
import { SellerStates } from '@/legacy/sellers/sellerSummary.model';
import { useGhostStore } from '@/legacy/store';

export const useIsDisplaySellerLink = () => {
  const currentUser = useGhostStore((state) => state.user);
  const pathname = usePathname();

  return currentUser?.seller?.state === SellerStates.ACTIVATED && !pathname.startsWith('/seller');
};
