import { BuyerPreferences } from '@/app/(authenticated)/(marketplace)/_components/types';

export interface BuyerPreferencesState {
  buyerPreferences: BuyerPreferences | null;
  setBuyerPreferences: (buyerPreferences: BuyerPreferences) => void;
}

export const buyerPreferencesState = (set: any): BuyerPreferencesState => ({
  buyerPreferences: null,
  setBuyerPreferences: (buyerPreferences) => set(() => ({ buyerPreferences })),
});
