'use client';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { UserMenu } from '@/components/UserMenu';
import { useGhostStore } from '@/legacy/store';
import { GhostCoinSpline } from '../GhostCoinSpline';
import Quotes from './Quotes';

const QuotesRightSide = (): JSX.Element => {
  const currentUser = useGhostStore((state) => state.user);
  const theme = useTheme();

  return (
    <Box height="100%" pb={5} bgcolor={theme.palette.common.black}>
      {Boolean(currentUser) && (
        <Box position="absolute" top={5} right={10}>
          <UserMenu isDark />
        </Box>
      )}
      <Stack direction="column" height="100%" alignItems="center" justifyContent="center">
        <GhostCoinSpline svgSxProps={{ maxWidth: 300, mb: 5 }} />
        <Quotes isDarkMode />
      </Stack>
    </Box>
  );
};

export default QuotesRightSide;
