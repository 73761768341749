import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/Box/Box.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/Button/Button.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/Link/Link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/node_modules/@mui/system/useMediaQuery/useMediaQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/Stack/Stack.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/Typography/Typography.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/SubmitButton/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/TwoSidesLayout/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/TwoSidesLayout/QuotesRightSide.tsx");
