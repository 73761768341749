import React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

type Props = {
  isDarkMode?: boolean;
};

const NeedHelp = ({ isDarkMode = false }: Props) => (
  <Typography
    variant="bodySmallLight"
    color={isDarkMode ? 'typographyDarkInverted' : 'typographyDarkPrimary'}
  >
    Need help? Email us at <Link href="mailto:hello@ghst.io">hello@ghst.io</Link>
  </Typography>
);

export default NeedHelp;
