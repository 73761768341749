'use client';

import { useFormStatus } from 'react-dom';
import Button, { ButtonProps } from '@mui/material/Button';

export default function SubmitButton({
  children,
  disabled,
  ...otherProps
}: Omit<ButtonProps, 'type'>): JSX.Element {
  const { pending } = useFormStatus();

  return (
    <Button disabled={pending || disabled} type="submit" {...otherProps}>
      {children}
    </Button>
  );
}
