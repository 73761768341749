import { AnalyticsBrowser } from '@segment/analytics-next';

export const analyticsBrowser = new AnalyticsBrowser();

// Export the analytics object with the identify, track, and page methods
export const analytics = {
  identify: analyticsBrowser.identify,
  page: analyticsBrowser.page,
  track: analyticsBrowser.track,
};
