import { BuyerTypeNames } from '../sellers/PublicCreateLot/types';

export enum BuyerStates {
  ACTIVATED = 'activated',
  DEACTIVATED = 'deactivated',
  CREATED = 'created',
}

export interface BuyerSummary {
  buyerTypeNames: BuyerTypeNames[];
  id: number;
  state: BuyerStates;
}
