import { usePathname } from 'next/navigation';
import { BuyerStates } from '@/legacy/buyers/buyerSummary.model';
import { useGhostStore } from '@/legacy/store';

export const useIsDisplayBuyerLink = () => {
  const currentUser = useGhostStore((state) => state.user);
  const pathname = usePathname();

  return (
    currentUser?.buyer &&
    [BuyerStates.ACTIVATED, BuyerStates.CREATED].includes(currentUser.buyer.state) &&
    (pathname.startsWith('/seller') || pathname.startsWith('/referrer'))
  );
};
