import { usePathname } from 'next/navigation';
import { ReferrerStates } from '@/legacy/referrers/referrerSummary.model';
import { useGhostStore } from '@/legacy/store';

export const useIsDisplayReferrerLink = (isReferralEnabled: boolean) => {
  const currentUser = useGhostStore((state) => state.user);
  const pathname = usePathname();

  return (
    !!isReferralEnabled &&
    currentUser?.referrer?.state === ReferrerStates.ACTIVATED &&
    !pathname.startsWith('/referrer')
  );
};
