'use client';

import React from 'react';
import { Ghost } from '@ghosts-inc/design-system/icons';
import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import { UserMenu } from '@/components/UserMenu';
import { useGhostStore } from '@/legacy/store';
import NeedHelp from './NeedHelp';

type HeaderProps = {
  isDarkMode?: boolean;
  isSuccessView?: boolean;
};

const Header = ({ isDarkMode = false, isSuccessView = false }: HeaderProps) => {
  // Gradient Layout refers to the two-pane layout with gradient on right for desktop
  const isGradientLayout = useMediaQuery((theme: Theme) => theme.breakpoints.up('signupLayout'));
  const currentUser = useGhostStore((state) => state.user);
  const shouldShowUserMenu = (!isGradientLayout || isSuccessView) && Boolean(currentUser);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      mb={4.5}
      pt={2.5}
      px={{
        mobile: 2,
        signupLayout: 4,
      }}
      position="relative"
      color={(theme) => (isDarkMode ? theme.palette.common.white : theme.palette.common.black)}
    >
      <Ghost sx={{ fontSize: 46 }} />
      <NeedHelp isDarkMode={isDarkMode} />
      {shouldShowUserMenu && (
        <Box>
          <UserMenu isDark={isSuccessView} />
        </Box>
      )}
    </Stack>
  );
};

export default Header;
