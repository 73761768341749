export enum ReferrerStates {
  ACTIVATED = 'activated',
  DEACTIVATED = 'deactivated',
}

export interface ReferrerSummary {
  accountManagerEmail?: string;
  id: number;
  state: ReferrerStates;
}
