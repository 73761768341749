import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { BuyerPreferencesState, buyerPreferencesState } from './buyer-preferences-state';
import { UserState, userState } from './user-state';

export const useGhostStore = create<UserState & BuyerPreferencesState>()(
  devtools((set) => ({
    ...userState(set),
    ...buyerPreferencesState(set),
  })),
);
